import * as React from "react";
import { Link } from "gatsby";
import Layout from "@/components/Layout";
import what from "../images/what.gif"

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="flex justify-center">
        <div>
          <h1 className="align-items-center"> 😕 Oh no!! It seems like you're lost...</h1>
        </div>
      </div>
      <div className="mt-10 flex justify-center"><Link to="/" className="px-4 py-2 bg-blue-100 rounded-full text-blue hover:bg-blue-600 transition-all animate-waving-hand ease-in-out duration-300 hover:text-white uppercase">Go Home</Link></div>
      <div className="my-10 flex justify-center">
        <iframe src="https://giphy.com/embed/TIiyIEh2FUN7aTESJJ" width="480" height="444" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
